<template>
  <v-container fluid>
    <v-row v-if="isLoaded" no-gutters class="mt-6">

      <!-- LEFT PANEL -->
      <v-col cols="12" class="mt-6">
        <v-row class="white elevation-3 mx-2" no-gutters>
          <v-col cols="12" style="background-color: rgb(245, 245, 245);">
            <v-row justify="space-between" no-gutters>
              <v-spacer/>

              <!-- Position chip -->
              <v-chip
                v-if="item.position"
                label
                class="mx-2 my-2"
              >{{item.position}}
              </v-chip>

              <!-- Role chip -->
              <v-chip
                color="error"
                v-if="item.user.role"
                label
                class="mx-2 my-2"
              >{{item.user.role}}
              </v-chip>

              <!-- Edit button -->
              <BtnIconEdit
                @click="setShowMainEditDialog(true)"
                class="mx-2 my-2"/>
            </v-row>

            <v-row justify="center" align="center" no-gutters class="my-4">

              <!-- Picture -->
              <v-col cols="12" md="3">
                <DisplayPictureEditable
                  class="ma-2"
                  :img="item.person.img"
                  :name="`${item.person.firstname} ${item.person.name}`"
                  @changed="pictureChanged"/>
              </v-col>

              <!-- Name -->
              <v-col cols="12" md="9" class="text-center">
                <h1>{{ item.person.firstname }} {{ item.person.name }}</h1>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-2">
            <v-row no-gutters>
              <div class="my-4">
                <ProfilePreferences
                  :preferences="item.preferences"
                  :registration_date="null"
                />
              </div>
            </v-row>
          </v-col>

        </v-row>
      </v-col>

      <!-- RIGHT PANEL -->
      <v-col cols="12" class="mt-6">
        <v-row no-gutters>

          <!-- Main Email -->
          <v-col cols="12" md="6" class="mt-6">
            <div class="subheading font-weight-bold mx-2">
              <v-hover>
                <v-card slot-scope="{hover}" :class="`elevation-${hover ? 5 : 2}`" style="min-height: 100px;">
                  <v-row justify="space-between" no-gutters>
                    <h3 class="ml-3 mt-3">{{ $t('profile.email.texts.cardTitle') }}</h3>
                  </v-row>

                  <div class="text-label ml-3 my-2">{{ item.user.email }}</div>
                </v-card>
              </v-hover>
            </div>

          </v-col>

          <!-- Password -->
          <v-col cols="12" md="6" class="mt-6">
            <div class="font-weight-bold mx-2">
              <v-hover>
                <v-card slot-scope="{hover}" :class="`elevation-${hover ? 5 : 2}`" style="min-height: 100px;">
                  <v-row justify="space-between" no-gutters>
                    <h3 class="ml-3 mt-3">{{ $t('profile.password.texts.cardTitle') }}</h3>
                    <div>
                      <BtnIconEdit v-on:click="setShowPasswordEditDialog(true)" class="mr-2"/>
                    </div>
                  </v-row>

                  <div v-if="item.user.last_password_update" class="detail-text-label mt-2 ml-3">
                    {{ $t('profile.password.texts.last_password_modification') }}
                    {{ datetimeEventsFormatter(item.user.last_password_update) }}
                  </div>
                  <div v-else class="text-label mt-2 ml-3">{{ $t('profile.password.texts.never_modified') }}
                  </div>
                </v-card>
              </v-hover>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Main Account Dialog -->
    <ProfileMainDialog
      v-if="showDialog.main"
      :value="showDialog.main"
      :item="{person: item.person, preferences: item.preferences, user: item.user, position: item.position}"
      @close="(needRefresh) => setShowMainEditDialog(false, needRefresh)"
    />

    <!-- Email dialog -->
    <ProfileEmailDialog
      v-if="showDialog.mainEmail"
      :value="showDialog.mainEmail"
      :item="{email: item.email}"
      @close="(needRefresh) => setShowMainEmailEditDialog(false, needRefresh)"
    />

    <!-- Password dialog -->
    <ProfilePasswordDialog
      v-if="showDialog.password"
      :value="showDialog.password"
      @close="(needRefresh) => setShowPasswordEditDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>

import formatter from "@/mixins/formatter";

export default {

  name: "ProfileView",

  components: {
    BtnIconEdit: () => import("@/components/Common/Buttons/BtnIconEdit"),
    DisplayPictureEditable: () => import("@/components/Profile/DisplayPictureEditable"),
    ProfilePreferences: () => import("@/components/Profile/Preferences"),
    ProfileMainDialog: () => import("@/components/Profile/ProfileMainDialog"),
    ProfilePasswordDialog: () => import("@/components/Profile/ProfilePasswordDialog"),
    ProfileEmailDialog: () => import("@/components/Profile/ProfileEmailDialog")
  },

  mixins: [formatter],

  data() {
    return {
      showDialog: {
        main: false,
        mainEmail: false,
        password: false,
      },

      isLoaded: false,
      isLoading: {
        btnSave: false
      },

      item: null,
      URL_API: {
        EDIT_PICTURE: `/fm-accounts/${this.$session.get('id')}/picture`
      }
    }
  },

  created() {
    this.getData();
  },


  methods: {

    getData() {
      this.isLoaded = false;
      this.isLoading.btnSave = false;

      this.$http
        .get(`/fm-accounts/${this.$session.get("id")}`, {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res => {
          this.item = res.data;

          this.$session.set('person.firstname', res.data.person.firstname);
          this.$session.set('person.name', res.data.person.name);

          if (res.data.picture)
            this.$store.commit("profile/setImg", res.data.picture.URL);
          else
            this.$store.commit("profile/setImg", null);

          this.$i18n.locale = res.data.preferences.lang;
          this.$session.set("lang", this.$i18n.locale);
          this.$vuetify.lang.current = this.$i18n.locale;

          this.$store.commit("pageHead/setTitle", `${this.item.person.firstname} ${this.item.person.name}`);
        })
        .catch(err => {
          console.log(err);
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        })
    },

    setShowMainEditDialog(val, reloadData = false) {
      if (reloadData)
        this.getData();
      this.showDialog.main = val;
    },

    setShowMainEmailEditDialog(val, reloadData = false) {

      this.showDialog.mainEmail = val;
      if (reloadData) {
        this.$store.dispatch("logout");
      }
    },

    setShowPasswordEditDialog(val, reloadData = false) {
      this.showDialog.password = val;
      if (reloadData) {
        this.$store.dispatch("logout");
      }
    },

    pictureChanged() {
      this.getData();
    }
  },
}
</script>

<style scoped>

</style>